import React, { FC } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Container, Grid, Theme, Typography } from '@material-ui/core';
import TextContent, { TextContentProps } from 'components/energy5/structures/TextContent';
import { useMediaQueries } from 'hooks';
import clsx from 'clsx';
import Image from 'next/image';

type TextProps = Omit<TextContentProps, 'textClassName'>;
interface ImageSectionProps extends TextProps {
  imageSrc: string;
  width: number;
  height: number;
  className?: string;
  imageClassName?: string;
  showBlueBgEffect?: boolean;
  setCenterText?: boolean;
  setSpaceBetweenLg?: boolean;
  upperTitle?: string;
  imageLeft?: boolean;
  marginType?: 1 | 2 | 3 | 4;
  imageBigLg?: boolean;
  setImageBorder?: boolean;
}
const useStyles = makeStyles((theme: Theme) => {
  return {
    rootEffect: {
      position: 'relative',
      overflow: 'hidden',
    },
    root: {
      padding: theme.spacing(10, 0),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(20, 0),
      },
    },
    textBox: {
      marginBottom: theme.spacing(8),
      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(0),
      },
    },
    exploreImg: {
      width: '100%',
      borderRadius: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        maxHeight: 400,
        objectFit: 'cover',
      },
    },
    exploreImgBorder: {
      border: `1px solid ${theme.palette.customColors.lightGray}`,
    },
    bgDecoration: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        zIndex: '-1',
        display: 'block',
        position: 'absolute',
        height: 'calc(100% + 70px)',
        width: '50%',
        backgroundColor: theme.palette.customColors.light,
        borderRadius: theme.spacing(3),
        top: -40,
        right: ({ imageLeft }: Partial<ImageSectionProps>) => (imageLeft ? 'inherit' : -70),
        left: ({ imageLeft }: Partial<ImageSectionProps>) => (imageLeft ? -70 : 'inherit'),
      },
    },
    container: {
      flexDirection: ({ imageLeft }: Partial<ImageSectionProps>) =>
        imageLeft ? 'row-reverse' : 'row',
      alignItems: ({ setCenterText }: Partial<ImageSectionProps>) =>
        setCenterText ? 'center' : 'flex-start',
    },
    upperTitle: {
      fontWeight: 800,
      color: theme.palette.common.black,
      marginBottom: theme.spacing(6),
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(7),
      },
    },
    textContentWrapper: {
      [theme.breakpoints.up('md')]: {
        paddingTop: ({ setCenterText }: Partial<ImageSectionProps>) =>
          theme.spacing(setCenterText ? 0 : 6),
      },
    },
  };
});

const marginStyles = makeStyles((theme: Theme) => {
  return {
    root1: {
      [theme.breakpoints.down('sm')]: {
        paddingBottom: 0,
      },
      [theme.breakpoints.up('lg')]: {
        paddingTop: theme.spacing(40),
        paddingBottom: 0,
      },
    },
    imageClass1: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(-14),
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: theme.spacing(-15),
      },
    },
    root2: {
      backgroundColor: theme.palette.secondary.main,
      paddingTop: theme.spacing(27),
      [theme.breakpoints.up('lg')]: {
        paddingTop: theme.spacing(38),
        paddingBottom: 0,
      },
    },
    imageClass2: {
      [theme.breakpoints.up('lg')]: {
        marginBottom: theme.spacing(-15),
      },
    },
    root3: {
      [theme.breakpoints.down('sm')]: {
        paddingBottom: 0,
      },
      [theme.breakpoints.up('lg')]: {
        paddingBottom: 0,
      },
    },
    imageClass3: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: -70,
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: -75,
      },
    },
    root4: {
      [theme.breakpoints.up('lg')]: {
        paddingTop: theme.spacing(40),
      },
    },
  };
});

const ImageSection: FC<ImageSectionProps> = ({
  title,
  className,
  imageClassName,
  imageSrc,
  width,
  height,
  link,
  text,
  showBlueBgEffect,
  imageLeft,
  upperTitle,
  setSpaceBetweenLg,
  setCenterText,
  enableDarkMode,
  underTitle,
  marginType,
  imageBigLg,
  setImageBorder,
}) => {
  const classes = useStyles({ imageLeft, setCenterText });
  const marginClasses = marginStyles();
  const isMediumUp = useMediaQueries((theme: Theme) => theme.breakpoints.up('md'));

  const giveMarginClass = (marginType): { imageClass: string; rootClass: string } => {
    switch (marginType) {
      case 1:
        return { imageClass: marginClasses.imageClass1, rootClass: marginClasses.root1 };
      case 2:
        return { imageClass: marginClasses.imageClass2, rootClass: marginClasses.root2 };
      case 3:
        return { imageClass: marginClasses.imageClass3, rootClass: marginClasses.root3 };
      case 4:
        return { imageClass: '', rootClass: marginClasses.root4 };
      default:
        return { imageClass: '', rootClass: '' };
    }
  };
  const marginClass = giveMarginClass(marginType);

  return (
    <Box
      className={clsx(
        classes.root,
        showBlueBgEffect && classes.rootEffect,
        marginClass.rootClass,
        className
      )}
    >
      <Container maxWidth={false} fixed>
        <Grid
          container
          justifyContent="space-between"
          spacing={isMediumUp ? 6 : 0}
          className={classes.container}
        >
          <Grid item lg={imageBigLg ? 4 : 5} md={6} xs={12} className={classes.textBox}>
            <Box className={classes.textContentWrapper}>
              {!!upperTitle && (
                <Typography variant="h2" className={classes.upperTitle}>
                  {upperTitle}
                </Typography>
              )}
              <TextContent
                enableDarkMode={enableDarkMode}
                title={title}
                underTitle={underTitle}
                text={text}
                link={link}
              />
            </Box>
          </Grid>
          <Grid item lg={imageBigLg || !setSpaceBetweenLg ? 7 : 6} md={6} xs={12}>
            <Box style={{ position: 'relative' }}>
              <Image
                src={imageSrc}
                width={width}
                height={height}
                alt={title}
                className={clsx(
                  classes.exploreImg,
                  marginClass.imageClass,
                  setImageBorder && classes.exploreImgBorder,
                  imageClassName
                )}
              />
              {showBlueBgEffect && <Box className={classes.bgDecoration} />}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ImageSection;
