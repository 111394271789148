import React, { FC, ReactNode } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Grid, Theme, Typography, useMediaQuery } from '@material-ui/core';

export interface InlineListCardItem {
  text: string;
  image: ReactNode;
}

interface InlineListCardsProps {
  data: Array<InlineListCardItem>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(14, 0),
  },

  listCard: {
    padding: theme.spacing(4.8, 3),
    minHeight: 165,
    backgroundColor: theme.palette.customColors.lightGray,
    borderRadius: theme.spacing(2),
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      minHeight: 190,
    },
  },

  svgIcon: {
    marginBottom: theme.spacing(3),
  },
}));

const InlineListCards: FC<InlineListCardsProps> = ({ data }) => {
  const classes = useStyles();
  const isMobileUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  return (
    <Grid container spacing={isMobileUp ? 3 : 2} className={classes.root}>
      {data.map((card, index) => (
        <Grid item lg md={6} xs={12} key={index}>
          <Box className={classes.listCard}>
            <Box className={classes.svgIcon}>{card.image}</Box>
            <Typography variant="h6" color="secondary">
              <strong>{card.text}</strong>
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default InlineListCards;
