import React, { FC, ReactNode } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Container, Grid, Theme, Typography } from '@material-ui/core';
import { Energy5Link } from 'components/energy5/atoms';
import { useMediaQueries } from 'hooks';
import clsx from 'clsx';

export interface ContentListImageGridProps {
  subTitle?: string;
  title: { text: string; highlightText: string; beforeHighlightText?: string };
  description: string;
  link: { text: string; href: string; isAnchor?: boolean };
  imageSrc: string;
  setMdSmallPadding?: boolean;
  contentLg?: 5 | 6;
  descriptionList?: Array<{ icon: ReactNode; title: string; description: string }>;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      padding: theme.spacing(10, 0, 20),
      backgroundColor: '#f5f5f5',
    },
    rootSmallPadding: {
      padding: theme.spacing(10, 0, 30),
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(2),
      },
    },
    subTitle: {
      color: theme.palette.common.black,
      fontWeight: 800,
    },
    title: {
      fontWeight: 900,
      whiteSpace: 'pre-line',
      marginBottom: theme.spacing(5),
      '& span': {
        color: theme.palette.common.black,
      },
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 38,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 42,
      },
    },
    text: {
      color: '#4b4b4b',
      lineHeight: 1.5,
      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
      },
    },
    image: {
      marginTop: theme.spacing(10),
      '& img': {
        width: '100%',
        maxWidth: 600,
        display: 'block',
        maxHeight: 300,
        objectFit: 'contain',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
        '& img': {
          maxHeight: 'inherit',
          objectFit: 'inherit',
        },
      },
    },
    descriptionWrapper: {
      marginTop: theme.spacing(13),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(16),
      },
    },
    descriptionItem: {
      padding: theme.spacing(3),
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      minHeight: '100%',
    },
    descriptionIcon: {
      marginRight: theme.spacing(2),
    },
    descriptionTitle: {
      fontSize: 13,
      fontWeight: 800,
      color: theme.palette.common.black,
    },
    descriptionText: {
      fontSize: 11,
      fontWeight: 600,
      color: theme.palette.common.black,
    },
  };
});

const ContentListImageGrid: FC<ContentListImageGridProps> = ({
  subTitle,
  title,
  description,
  link,
  imageSrc,
  descriptionList,
  setMdSmallPadding = false,
  contentLg = 5,
}) => {
  const classes = useStyles();
  const isMediumUp = useMediaQueries((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Box className={clsx(classes.root, setMdSmallPadding && classes.rootSmallPadding)}>
      <Container maxWidth={false} fixed>
        <Grid container alignItems="center" spacing={isMediumUp ? 4 : 0}>
          <Grid item lg={contentLg} md={6} xs={12}>
            <Box>
              {subTitle && (
                <Box mb={2}>
                  <Typography variant="h4" className={classes.subTitle}>
                    {subTitle}
                  </Typography>
                </Box>
              )}
              <Typography variant="h1" className={classes.title} color="primary">
                {title.beforeHighlightText || ''}{' '}
                <Typography component="span" variant="inherit">
                  {title.highlightText}{' '}
                </Typography>
                {title.text}
              </Typography>
              <Typography variant="h5" className={classes.text}>
                {description}
              </Typography>
              {link && (
                <Box mt={8}>
                  <Energy5Link text={link.text} href={link.href} isAnchor={link.isAnchor} />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box className={classes.image}>
              <img src={imageSrc} alt={title.highlightText} />
            </Box>
          </Grid>
        </Grid>
        {descriptionList && (
          <Box className={classes.descriptionWrapper}>
            <Grid container spacing={isMediumUp ? 6 : 3}>
              {descriptionList.map((item, index) => (
                <Grid item md={3} sm={6} xs={12} key={index}>
                  <Box className={classes.descriptionItem}>
                    <Box className={classes.descriptionIcon}>{item.icon}</Box>
                    <Box>
                      <Typography className={classes.descriptionTitle}>{item.title}</Typography>
                      <Typography className={classes.descriptionText}>
                        {item.description}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default ContentListImageGrid;
