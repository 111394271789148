import React, { FC, ReactNode } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Container, Grid, Theme, Typography } from '@material-ui/core';
import { TextContent } from 'components/energy5/structures';
import { TextContentProps } from 'components/energy5/structures/TextContent';
import { useMediaQueries } from 'hooks';
import clsx from 'clsx';

interface FlexibleCards {
  title: string;
  text?: string;
  image: ReactNode;
}

type TextProps = Pick<TextContentProps, 'title' | 'text'>;

export interface FlexibleCardsProps extends TextProps {
  columns: Array<Array<FlexibleCards>>;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      padding: theme.spacing(14, 0, 0),
    },
    card: {
      padding: theme.spacing(5, 3),
      backgroundColor: theme.palette.customColors.lightGray,
      border: '1px solid #EEEEEE',
      borderRadius: theme.spacing(2),
      width: '100%',
      marginTop: theme.spacing(5),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(5, 3),
        marginTop: theme.spacing(6),
        minHeight: 275,
      },
    },
    miniCard: {
      textAlign: 'center',
      minHeight: 160,
      [theme.breakpoints.down('md')]: {
        '&:not(:last-child)': {
          marginRight: theme.spacing(5),
        },
      },
    },
    miniCardWrapper: {
      [theme.breakpoints.up('lg')]: {
        '&:nth-child(2)': {
          marginTop: theme.spacing(-24),
        },
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
      },
    },
    icon: {
      marginBottom: theme.spacing(3),
    },
    title: {
      fontWeight: 700,
    },
    text: {
      marginTop: theme.spacing(3),
    },
  };
});

const FlexibleCardsContent: FC<FlexibleCardsProps> = ({ columns, text, title }) => {
  const classes = useStyles();
  const isFullCard = columns[0][0].text;
  const isMediumUp = useMediaQueries((theme: Theme) => theme.breakpoints.up('md'));
  const isLargeUp = useMediaQueries((theme: Theme) => theme.breakpoints.up('lg'));
  return (
    <Box>
      <Container maxWidth={false} fixed>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={isMediumUp ? 6 : 0}
          className={classes.container}
        >
          <Grid item lg={isFullCard ? 4 : 6} xs={12}>
            <TextContent title={title} text={text} />
          </Grid>
          <Grid item lg={isFullCard ? 8 : 6} xs={12}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={isLargeUp ? 6 : 0}
            >
              {columns.map((column, index) => (
                <Grid
                  item
                  lg={4}
                  xs={12}
                  key={index}
                  className={clsx(!isFullCard && classes.miniCardWrapper)}
                >
                  {column.map((card) => (
                    <Box
                      className={clsx(classes.card, !isFullCard && classes.miniCard)}
                      key={card.title}
                    >
                      <Box className={classes.icon}>{card.image}</Box>
                      <Typography variant={'h6'} className={classes.title} color="secondary">
                        {card.title}
                      </Typography>
                      {card.text && (
                        <Typography variant={'body2'} className={classes.text}>
                          {card.text}
                        </Typography>
                      )}
                    </Box>
                  ))}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FlexibleCardsContent;
