import React, { FC } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Theme, Typography } from '@material-ui/core';
import { Energy5Link } from 'components/energy5/atoms';
import clsx from 'clsx';
import { Energy5LinkProps } from 'components/energy5/atoms/Energy5Link';

type LinkProps = Omit<Energy5LinkProps, 'isAnchor' | 'className'>;

export interface TextContentProps {
  title?: string;
  underTitle?: string;
  text: string;
  textClassName?: string;
  link?: LinkProps;
  enableDarkMode?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    borderLeft: `3px solid ${theme.palette.customColors.light}`,
    paddingLeft: theme.spacing(3),
    color: ({ enableDarkMode }: Partial<TextContentProps>) =>
      enableDarkMode ? theme.palette.common.white : theme.palette.secondary.main,
  },
  textBlack: {
    color: theme.palette.common.black,
  },
  text: {
    color: ({ enableDarkMode }: Partial<TextContentProps>) =>
      enableDarkMode ? theme.palette.customColors.lightGray : theme.palette.text.primary,
    whiteSpace: 'pre-line',
  },
}));

const TextContent: FC<TextContentProps> = ({
  enableDarkMode,
  title,
  text,
  textClassName,
  link,
  underTitle,
  children,
}) => {
  const classes = useStyles({ enableDarkMode });

  return (
    <>
      {title && (
        <Box>
          <Typography variant="h3" className={classes.title}>
            {title}
          </Typography>
        </Box>
      )}
      {underTitle && (
        <Box mt={4}>
          <Typography variant="h5" className={classes.textBlack}>
            {underTitle}
          </Typography>
        </Box>
      )}
      <Box mt={4} mb={!!link || !!children ? 6 : 0}>
        <Typography variant="h6" className={clsx(classes.text, textClassName)}>
          {text}
        </Typography>
      </Box>
      {link && <Energy5Link href={link.href} text={link.text} onClick={link.onClick} />}
      {children}
    </>
  );
};

export default TextContent;
