import React, { FC } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Grid, Theme, Container, useMediaQuery } from '@material-ui/core';
import TextContent, { TextContentProps } from 'components/energy5/structures/TextContent';
import clsx from 'clsx';

enum ParallaxPosition {
  left = 'flex-start',
  right = 'flex-end',
}

interface ImagesParallaxTextContentProps extends TextContentProps {
  background: string;
  backgroundEffect: string;
  position?: keyof typeof ParallaxPosition;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    imageEffectPC: {
      backgroundAttachment: 'fixed',
    },
    imageEffect: {
      position: 'relative',
      backgroundRepeat: 'no-repeat',
      backgroundColor: theme.palette.secondary.main,
      minHeight: 360,
      backgroundSize: 'cover',
      maxHeight: 760,
      display: 'flex',
      alignItems: 'flex-end',
      [theme.breakpoints.up('sm')]: {
        minHeight: 'calc(100vh - 68px)',
        alignItems: 'center',
      },
    },
    contentWrapper: {
      padding: theme.spacing(10, 1),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(10, 0),
      },
    },
    photoWrap: {
      clip: 'rect(0,auto,auto,0)',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    img: {
      backgroundColor: '#333',
      position: 'fixed',
      display: 'block',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      zIndex: 1,
      transform: 'translateZ(0)',
      willChange: 'transform',
      '-webkit-backface-visibility': 'hidden',
      '-webkit-transform': 'translate3d(0,0,0)',
    },
  };
});

const ParallaxWrapper: FC<{
  background: string;
  isMobile: boolean;
}> = ({ children, background, isMobile }) => {
  const classes = useStyles();
  return (
    <>
      {isMobile ? (
        <>
          <div className={classes.imageEffect}>
            <div className={classes.photoWrap}>
              <div className={classes.img} style={{ backgroundImage: `url(${background})` }} />
            </div>
            <Box style={{ position: 'relative', zIndex: 1 }}>{children}</Box>
          </div>
        </>
      ) : (
        <Box
          className={clsx(classes.imageEffect, classes.imageEffectPC)}
          style={{ backgroundImage: `url(${background})` }}
        >
          {children}
        </Box>
      )}
    </>
  );
};

const ImagesParallaxTextContent: FC<ImagesParallaxTextContentProps> = ({
  background,
  backgroundEffect,
  position = 'right',
  link,
  text,
  title,
  enableDarkMode,
  children,
}) => {
  const classes = useStyles();
  const isSmallUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  return (
    <>
      <Box>
        <ParallaxWrapper isMobile={!isSmallUp} background={backgroundEffect} />
        <ParallaxWrapper background={background} isMobile={!isSmallUp}>
          <Container maxWidth={false} fixed>
            <Grid
              container
              justifyContent={ParallaxPosition[position]}
              className={classes.contentWrapper}
            >
              <Grid item lg={5} md={6} xs={12}>
                <TextContent text={text} title={title} enableDarkMode={enableDarkMode} link={link}>
                  {children}
                </TextContent>
              </Grid>
            </Grid>
          </Container>
        </ParallaxWrapper>
      </Box>
    </>
  );
};

export default ImagesParallaxTextContent;
