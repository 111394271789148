import React from 'react';
import MaskedInput from 'react-text-mask';

interface PhoneFormatProps {
  inputRef: (instance: MaskedInput | null) => void;
}

const PhoneFormat: React.FC<PhoneFormatProps> = (props) => {
  const { inputRef, ...rest } = props;

  return (
    <MaskedInput
      {...rest}
      showMask
      mask={[
        /[+]/,
        /[1]/,
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );
};

export default PhoneFormat;
